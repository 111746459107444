<script lang="ts">
	import { dayjs } from '@woltair/translations-package-fe'
	import { BLOG } from '$lib/routes'
	//import type { Article } from '$lib/types/strapi4'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	export let article: any
	export let hide = false

	const dateFormat = {
		WOLTAIR_CZ: 'D. MMMM YYYY',
		WOLTAIR_PL: 'D.MM.YYYY',
		WOLTAIR_DE: 'D. MMMM YYYY',
		WOLTAIR_IT: 'D MMMM YYYY'
	}[PUBLIC_WOLTAIR_WEB_CODE]

	$: imageAttributes = article.attributes?.mainImage?.data
		? article.attributes?.mainImage?.data[0]?.attributes
		: null

	$: categorySlug = article.attributes?.originalCategory
		? article.attributes?.originalCategory
		: article.attributes?.categories?.data[0]?.attributes?.slug

	$: articleUrl = article.attributes?.legacy
		? `/${BLOG}/${categorySlug}/${article.attributes.slug}`
		: `/${BLOG}/${article.attributes?.slug}`

	$: imgUrl = article?.attributes?.mainImage?.data?.[0]?.attributes?.url?.length
		? new URL(article.attributes.mainImage.data[0].attributes?.url)
		: new URL(
				'https://imagedelivery.net/rggusss5mXAsA02SHxOMGg/7aec1561-35b2-4a4c-5cb1-c5852535d500/public'
		  )

	const imgWidth = 400

	$: imgHeight =
		imageAttributes?.height && imageAttributes?.width
			? Math.floor((imageAttributes.height / imageAttributes.width) * imgWidth)
			: 300

	$: imgUrlResized = `${imgUrl.origin}${imgUrl.pathname
		.split('/')
		.map((part, i, arr) => {
			if (part === 'public' && i === arr.length - 1) return `width=${imgWidth}`
			return part
		})
		.join('/')}`
</script>

<a
	data-sveltekit-preload-data
	href={articleUrl}
	class="article rounded-xl"
	class:hide-mobile={hide}
>
	<div class="img-wrapper">
		<img
			src={imgUrlResized}
			alt="{article.attributes?.title}, Woltair"
			width={imgWidth}
			height={imgHeight}
		/>
	</div>
	<div class="text-wrapper">
		<p class="text-sm">
			{dayjs(article.attributes?.publishDate).format(dateFormat)}
		</p>
		<h4>{@html article.attributes?.title}</h4>
	</div>
</a>

<style lang="scss">
	.article {
		width: 400px;
		position: relative;
		overflow: hidden;

		@media only screen and (max-width: 760px) {
			width: 100%;
			padding: 0;
		}

		.img-wrapper {
			height: 300px;
			overflow: hidden;
			position: relative;

			&::before,
			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: rgba(30, 30, 30, 0.5);
				transition: 0.5s ease-in-out;
			}

			&::after {
				background: rgba(30, 30, 30, 0.8);
				width: 0;
				height: 100%;
				top: 0;
				left: 0;
				transform-origin: left;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&:hover .img-wrapper {
			&::after {
				width: 100%;
			}
		}

		.text-wrapper {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			padding: 30px;
			h4,
			p {
				margin: 0;
				color: #ffffff;
			}
		}
	}
</style>
